@charset "UTF-8";
:root {
  --jetpack--contact-form--border:1px solid #8c8f94;
  --jetpack--contact-form--border-color:#8c8f94;
  --jetpack--contact-form--border-size:1px;
  --jetpack--contact-form--border-style:solid;
  --jetpack--contact-form--border-radius:0px;
  --jetpack--contact-form--input-padding:16px;
  --jetpack--contact-form--font-size:16px;
  --jetpack--contact-form--error-color:#b32d2e;
  --jetpack--contact-form--inverted-text-color:#fff
}
.contact-form .clear-form {
  clear:both
}
.contact-form input::placeholder {
  transition:opacity .3s ease-out
}
.contact-form input:hover::placeholder {
  opacity:.5
}
.contact-form input:focus::placeholder {
  opacity:.3
}
:where(.contact-form input[type=text],
.contact-form input[type=email],
.contact-form input[type=tel],
.contact-form input[type=url],
.contact-form textarea) {
  border:1px solid #8c8f94;
  border-radius:0;
  box-sizing:border-box;
  font:inherit;
  padding:16px;
  width:100%
}
:where(.contact-form textarea) {
  height:200px
}
.contact-form .grunion-field {
  padding-left:max(var(--jetpack--contact-form--input-padding-left,16px),var(--jetpack--contact-form--border-radius));
  padding-right:max(var(--jetpack--contact-form--input-padding-left,16px),var(--jetpack--contact-form--border-radius))
}
.contact-form .grunion-field-wrap input,
.contact-form .grunion-field-wrap textarea {
  margin:0
}
.contact-form select {
  min-width:150px;
  padding:14px 7px
}
.contact-form input[type=checkbox],
.contact-form input[type=radio] {
  height:1rem;
  margin:0 .75rem 0 0;
  width:1rem
}
.contact-form input[type=checkbox] {
  margin-left:0;
  top:0
}
.contact-form label {
  display:block;
  float:none;
  font-weight:700;
  margin-bottom:.25em
}
.contact-form label.consent {
  align-items:center;
  display:flex;
  font-size:13px;
  font-weight:400;
  text-transform:uppercase
}
.contact-form label.consent-implicit input {
  display:none
}
.contact-form label.checkbox {
  font-weight:400
}
.contact-form label.checkbox-multiple,
.contact-form label.radio {
  flex:1;
  font-weight:400;
  margin-bottom:0
}
.contact-form .grunion-checkbox-multiple-options,
.contact-form .grunion-radio-options {
  align-items:flex-start;
  border:0;
  display:flex;
  flex-direction:column;
  gap:12px;
  margin:0;
  padding:0
}
.contact-form .is-style-outlined .grunion-checkbox-multiple-options,
.contact-form .is-style-outlined .grunion-radio-options {
  border:1px solid var(--jetpack--contact-form--border-color)
}
.contact-form .grunion-checkbox-multiple-options legend,
.contact-form .grunion-radio-options legend {
  font-weight:700;
  margin-bottom:.25em;
  padding:0
}
.contact-form .is-style-outlined .grunion-checkbox-multiple-options legend,
.contact-form .is-style-outlined .grunion-radio-options legend {
  font-size:.8em;
  font-weight:300;
  margin:0 0-.75em;
  padding:0 .25em
}
.contact-form .grunion-checkbox-multiple-options .contact-form-field,
.contact-form .grunion-radio-options .contact-form-field {
  align-items:baseline;
  display:flex;
  margin:0
}
.contact-form label span.required,
.grunion-label-required {
  font-size:85%;
  font-weight:400;
  margin-left:.25em;
  opacity:.6
}
.contact-form-submission {
  border-bottom:1px solid #000;
  border-top:1px solid #000;
  margin-bottom:4em;
  padding:1.5em 1em;
  width:100%
}
.contact-form-submission p {
  margin:0 auto;
  word-wrap:break-word
}
.contact-form-submission h4 {
  font-weight:200;
  margin-bottom:32px;
  margin-top:32px
}
.contact-form-submission .go-back-message {
  margin-bottom:32px;
  margin-top:20px;
  text-align:left
}
.contact-form-submission .go-back-message .link {
  color:#000;
  font-weight:200
}
.contact-form-submission .field-name {
  font-weight:200
}
.contact-form-submission .field-value {
  font-weight:600;
  margin-bottom:20px
}
.form-errors .form-error-message {
  color:var(--jetpack--contact-form--error-color)
}
.textwidget .contact-form input[type=email],
.textwidget .contact-form input[type=tel],
.textwidget .contact-form input[type=text],
.textwidget .contact-form input[type=url],
.textwidget .contact-form textarea,
.wp-block-column .contact-form input[type=email],
.wp-block-column .contact-form input[type=tel],
.wp-block-column .contact-form input[type=text],
.wp-block-column .contact-form input[type=url],
.wp-block-column .contact-form textarea {
  width:100%
}
#jetpack-check-feedback-spam {
  margin:1px 8px 0 0
}
.jetpack-check-feedback-spam-spinner {
  display:inline-block;
  margin-top:7px
}
.wp-block-jetpack-contact-form {
  display:flex;
  flex-direction:row;
  flex-grow:1;
  flex-wrap:wrap;
  gap:var(--wp--style--block-gap,1.5rem);
  justify-content:flex-start
}
.wp-block-jetpack-contact-form>* {
  box-sizing:border-box;
  flex:0 0 100%;
}
.wp-block-jetpack-contact-form-container.alignfull .wp-block-jetpack-contact-form {
  padding-left:0;
  padding-right:0
}
.wp-block-jetpack-button.alignright button {
  float:right
}
.wp-block-jetpack-contact-form .grunion-field-wrap {
  box-sizing:border-box;
  position:relative;
}
.wp-block-jetpack-contact-form .grunion-field-width-25-wrap {
  flex:1 1 calc(25% - var(--wp--style--block-gap, 1.5rem)*1);
  max-width:25%
}
.wp-block-jetpack-contact-form .grunion-field-width-50-wrap {
  flex:1 1 calc(50% - var(--wp--style--block-gap, 1.5rem)*1);
  max-width:50%
}
.wp-block-jetpack-contact-form .grunion-field-width-75-wrap {
  flex:1 1 calc(75% - var(--wp--style--block-gap, 1.5rem)*1);
  max-width:75%
}
@media only screen and (max-width:480px) {
  .wp-block-jetpack-contact-form .grunion-field-wrap {
    flex-basis:100%;
    max-width:none
  }
}
.grunion-field-consent-wrap {
  align-self:center
}
@media only screen and (min-width:600px) {
  .contact-form input[type=email],
  .contact-form input[type=tel],
  .contact-form input[type=text],
  .contact-form input[type=url] {
    width:50%
  }
  .wp-block-jetpack-contact-form input[type=email],
  .wp-block-jetpack-contact-form input[type=tel],
  .wp-block-jetpack-contact-form input[type=text],
  .wp-block-jetpack-contact-form input[type=url] {
    width:100%
  }
}
.jetpack-empty-spam,
.jetpack-empty-spam-container {
  display:inline-block
}
.jetpack-empty-spam-spinner {
  display:inline-block;
  margin-top:7px
}
.wp-block-jetpack-contact-form .wp-block-spacer {
  width:100%
}
.contact-form .contact-form__select-wrapper {
  position:relative
}
.contact-form .contact-form__select-wrapper:after {
  border-bottom:2px solid;
  border-right:2px solid;
  content:"";
  display:block;
  height:8px;
  inset-inline-end:calc(var(--jetpack--contact-form--input-padding) + 4px);
  pointer-events:none;
  position:absolute;
  top:calc(var(--jetpack--contact-form--input-padding) + var(--jetpack--contact-form--line-height)/2);
  transform:translateY(-50%) rotate(45deg);
  transform-origin:center center;
  width:8px
}
.contact-form .contact-form__select-wrapper select {
  -webkit-appearance:none;
  appearance:none;
  background-color:var(--jetpack--contact-form--input-background);
  border:var(--jetpack--contact-form--border);
  border-color:var(--jetpack--contact-form--border-color);
  border-radius:var(--jetpack--contact-form--border-radius);
  border-style:var(--jetpack--contact-form--border-style);
  border-width:var(--jetpack--contact-form--border-size);
  box-sizing:border-box;
  color:var(--jetpack--contact-form--text-color);
  font-family:var(--jetpack--contact-form--font-family);
  font-size:var(--jetpack--contact-form--font-size);
  line-height:var(--jetpack--contact-form--line-height);
  padding:var(--jetpack--contact-form--input-padding);
  padding-inline-end:calc(var(--jetpack--contact-form--input-padding)*3);
  text-overflow:ellipsis;
  white-space:nowrap;
  width:100%
}
.contact-form .is-style-animated,
.contact-form .is-style-outlined {
  --notch-width:max(var(--jetpack--contact-form--input-padding-left,16px),var(--jetpack--contact-form--border-radius))
}
.contact-form .is-style-animated .grunion-field-wrap:not(.grunion-field-checkbox-wrap):not(.grunion-field-consent-wrap):not(.grunion-field-checkbox-multiple-wrap):not(.grunion-field-radio-wrap):not(.grunion-field-select-wrap),
.contact-form .is-style-outlined .grunion-field-wrap:not(.grunion-field-checkbox-wrap):not(.grunion-field-consent-wrap):not(.grunion-field-checkbox-multiple-wrap):not(.grunion-field-radio-wrap):not(.grunion-field-select-wrap) {
  display:flex;
  flex-direction:row-reverse;
  position:relative
}
.contact-form .is-style-outlined .grunion-field-checkbox-multiple-wrap,
.contact-form .is-style-outlined .grunion-field-radio-wrap {
  background-color:var(--jetpack--contact-form--input-background)
}
.contact-form .is-style-outlined .grunion-field-wrap .grunion-checkbox-multiple-options,
.contact-form .is-style-outlined .grunion-field-wrap .grunion-radio-options {
  flex-grow:1;
  padding:var(--jetpack--contact-form--input-padding,16px);
  padding-top:calc(var(--jetpack--contact-form--input-padding-top, 16px) + 4px)
}
.contact-form .is-style-outlined .grunion-field-wrap .notched-label {
  box-sizing:border-box;
  display:flex;
  height:100%;
  left:0;
  max-width:100%;
  pointer-events:none;
  position:absolute;
  right:0;
  text-align:left;
  width:100%
}
.contact-form .contact-form__select-wrapper+.notched-label {
  top:0
}
.contact-form .is-style-outlined .grunion-field-wrap .notched-label .notched-label__leading {
  border:var(--jetpack--contact-form--border);
  border-color:var(--jetpack--contact-form--border-color);
  border-radius:var(--jetpack--contact-form--border-radius);
  border-bottom-right-radius:unset;
  border-style:var(--jetpack--contact-form--border-style);
  border-width:var(--jetpack--contact-form--border-size);
  border-right:none;
  border-top-right-radius:unset;
  width:var(--notch-width)
}
.contact-form .is-style-outlined .grunion-field-wrap .notched-label .notched-label__notch {
  border:var(--jetpack--contact-form--border);
  border-color:var(--jetpack--contact-form--border-color);
  border-radius:unset;
  border-style:var(--jetpack--contact-form--border-style);
  border-width:var(--jetpack--contact-form--border-size);
  border-left:none;
  border-right:none;
  padding:0 4px;
  transition:border .15s linear
}
.contact-form .is-style-outlined .grunion-field-wrap.no-label .notched-label__notch {
  padding:0
}
.contact-form .is-style-outlined .grunion-field-wrap .notched-label .notched-label__label {
  font-weight:300;
  margin:0;
  pointer-events:none;
  position:relative;
  top:50%;
  transform:translateY(-50%);
  transition:all .15s cubic-bezier(.4,0,.2,1);
  will-change:transform
}
.contact-form .is-style-outlined .grunion-field-textarea-wrap .notched-label .notched-label__label {
  top:var(--jetpack--contact-form--input-padding-top,16px);
  transform:unset
}
.contact-form .is-style-outlined .grunion-field-wrap .notched-label .notched-label__trailing {
  border:var(--jetpack--contact-form--border);
  border-color:var(--jetpack--contact-form--border-color);
  border-radius:var(--jetpack--contact-form--border-radius);
  border-bottom-left-radius:unset;
  border-style:var(--jetpack--contact-form--border-style);
  border-width:var(--jetpack--contact-form--border-size);
  border-left:none;
  border-top-left-radius:unset;
  flex-grow:1
}
.contact-form .is-style-outlined .grunion-field-wrap .grunion-checkbox-multiple-options~.notched-label .notched-label__notch,
.contact-form .is-style-outlined .grunion-field-wrap .grunion-field.has-placeholder~.notched-label .notched-label__notch,
.contact-form .is-style-outlined .grunion-field-wrap .grunion-field:focus~.notched-label .notched-label__notch,
.contact-form .is-style-outlined .grunion-field-wrap .grunion-field:not(:placeholder-shown)~.notched-label .notched-label__notch,
.contact-form .is-style-outlined .grunion-field-wrap .grunion-radio-options~.notched-label .notched-label__notch,
.contact-form .is-style-outlined .grunion-field-wrap.grunion-field-select-wrap .notched-label .notched-label__notch {
  border-top-color:#0000
}
.contact-form .is-style-outlined .grunion-field-wrap .grunion-checkbox-multiple-options~.notched-label .notched-label__label,
.contact-form .is-style-outlined .grunion-field-wrap .grunion-field.has-placeholder~.notched-label .notched-label__label,
.contact-form .is-style-outlined .grunion-field-wrap .grunion-field:focus~.notched-label .notched-label__label,
.contact-form .is-style-outlined .grunion-field-wrap .grunion-field:not(:placeholder-shown)~.notched-label .notched-label__label,
.contact-form .is-style-outlined .grunion-field-wrap .grunion-radio-options~.notched-label .notched-label__label,
.contact-form .is-style-outlined .grunion-field-wrap.grunion-field-select-wrap .notched-label .notched-label__label {
  font-size:.8em;
  top:calc(var(--jetpack--contact-form--border-size)*-1);
  transform:translateY(-50%)
}
.contact-form .is-style-outlined .grunion-field-wrap select,
.contact-form .is-style-outlined .grunion-field-wrap>input,
.contact-form .is-style-outlined .grunion-field-wrap>textarea {
  border-color:#0000!important;
  outline:0;
  padding-left:calc(var(--notch-width) + 4px);
  padding-right:calc(var(--notch-width) + 4px)
}
.contact-form .is-style-outlined .grunion-field-wrap textarea {
  padding:var(--jetpack--contact-form--input-padding,16px);
  padding-left:calc(var(--notch-width) + 4px);
  padding-right:calc(var(--notch-width) + 4px)
}
.contact-form .is-style-animated .grunion-field-wrap {
  --left-offset:calc(var(--jetpack--contact-form--input-padding-left, 16px) + var(--jetpack--contact-form--border-size));
  --label-left:max(var(--left-offset),var(--jetpack--contact-form--border-radius));
  --field-padding:calc(var(--label-left) - var(--jetpack--contact-form--border-size))
}
.contact-form .is-style-animated .grunion-field-wrap input:not([type=checkbox]):not([type=radio]) {
  outline:0
}
.contact-form .is-style-animated .grunion-field-wrap textarea {
  outline:0;
  padding:var(--jetpack--contact-form--input-padding,16px)
}
.contact-form .is-style-animated .grunion-field-wrap:not(.no-label) select,
.contact-form .is-style-animated .grunion-field-wrap:not(.no-label)>input,
.contact-form .is-style-animated .grunion-field-wrap:not(.no-label)>textarea {
  padding-left:var(--field-padding);
  padding-right:var(--field-padding);
  padding-top:1.4em
}
.contact-form .is-style-animated .grunion-field-wrap .animated-label__label {
  box-sizing:border-box;
  left:var(--label-left);
  margin:0;
  max-width:100%;
  pointer-events:none;
  position:absolute;
  top:50%;
  transform:translateY(-50%);
  transition:all .15s cubic-bezier(.4,0,.2,1);
  width:100%
}
.contact-form .is-style-animated .grunion-field-textarea-wrap .animated-label__label {
  top:var(--jetpack--contact-form--input-padding-top,16px);
  transform:unset
}
.contact-form .is-style-animated .grunion-field-wrap .grunion-field.has-placeholder~.animated-label__label,
.contact-form .is-style-animated .grunion-field-wrap .grunion-field:focus~.animated-label__label,
.contact-form .is-style-animated .grunion-field-wrap .grunion-field:not(:placeholder-shown)~.animated-label__label,
.contact-form .is-style-animated .grunion-field-wrap.grunion-field-select-wrap .animated-label__label {
  font-size:.75em;
  top:calc(2px + var(--jetpack--contact-form--border-size));
  transform:translateY(0)
}
.contact-form .is-style-animated .grunion-field-wrap .grunion-checkbox-multiple-options~.animated-label__label,
.contact-form .is-style-animated .grunion-field-wrap .grunion-radio-options~.animated-label__label {
  left:0;
  top:0;
  transform:translateY(0)
}
.contact-form .is-style-below .grunion-field-wrap .below-label__label {
  margin-left:var(--jetpack--contact-form--border-size)
}
.contact-form :where(.grunion-field-wrap:not(.is-style-button-wrap)) .grunion-checkbox-multiple-options,
.contact-form :where(.grunion-field-wrap:not(.is-style-button-wrap)) .grunion-radio-options {
  padding-top:8px
}
.contact-form .grunion-field-wrap input.checkbox-multiple,
.contact-form .grunion-field-wrap input.radio {
  -webkit-appearance:none;
  appearance:none;
  border:1px solid var(--jetpack--contact-form--text-color);
  box-sizing:border-box;
  height:var(--jetpack--contact-form--font-size);
  margin-inline-end:calc(var(--jetpack--contact-form--font-size)/2);
  outline-offset:4px;
  padding:0;
  position:relative;
  width:var(--jetpack--contact-form--font-size)
}
.contact-form .grunion-field-wrap input.radio {
  border-radius:50%;
  transform:translateY(15%)
}
.contact-form .grunion-field-wrap input.checkbox-multiple:checked:before {
  content:"✓";
  display:block;
  font-size:var(--jetpack--contact-form--font-size);
  left:calc(var(--jetpack--contact-form--font-size)/2);
  line-height:1;
  position:absolute;
  top:calc(var(--jetpack--contact-form--font-size)/2);
  transform:translate(-50%,-50%)
}
.contact-form .grunion-field-wrap input.radio:checked:before {
  background:currentColor;
  border-radius:50%;
  content:"";
  height:calc(var(--jetpack--contact-form--font-size)/2);
  margin-left:50%;
  margin-top:50%;
  position:absolute;
  transform:translate(-50%,-50%);
  width:calc(var(--jetpack--contact-form--font-size)/2)
}
.contact-form .grunion-field-wrap.grunion-field-checkbox-multiple-wrap.is-style-button-wrap .contact-form-field,
.contact-form .grunion-field-wrap.is-style-button-wrap .grunion-radio-label {
  align-items:center;
  background:var(--jetpack--contact-form--button-outline--background-color);
  border:var(--jetpack--contact-form--button-outline--border);
  border-radius:var(--jetpack--contact-form--button-outline--border-radius);
  color:var(--jetpack--contact-form--button-outline--text-color);
  display:inline-flex;
  line-height:var(--jetpack--contact-form--button-outline--line-height);
  padding:var(--jetpack--contact-form--button-outline--padding)
}
.contact-form .grunion-field-wrap.is-style-button-wrap .grunion-field.radio.is-style-button {
  clip:rect(0 0 0 0);
  -webkit-clip-path:inset(50%);
  clip-path:inset(50%);
  height:1px;
  overflow:hidden;
  position:absolute;
  white-space:nowrap;
  width:1px
}
.contact-form .grunion-field-wrap.is-style-button-wrap .grunion-field.radio.is-style-button:checked+.grunion-radio-label {
  display:inline-flex;
  gap:.5em
}
.contact-form .grunion-field-wrap.is-style-button-wrap .grunion-field.radio.is-style-button:checked+.grunion-radio-label:before {
  content:"✓"
}
.contact-form .grunion-field-wrap.grunion-field-checkbox-multiple-wrap.is-style-button-wrap .contact-form-field:focus-within,
.contact-form .grunion-field-wrap.is-style-button-wrap .grunion-field.radio.is-style-button:focus+.grunion-radio-label {
  outline:var(--jetpack--contact-form--button-outline--border);
  outline-offset:2px
}
.contact-form .grunion-field-wrap.is-style-button-wrap .grunion-field.checkbox-multiple.is-style-button {
  border-radius:var(--jetpack--contact-form--button-outline--border-radius);
  color:var(--jetpack--contact-form--button-outline--text-color);
  font-family:var(--wp--preset--font-family--body)
}
.contact-form .grunion-field-wrap.is-style-button-wrap .grunion-field.checkbox-multiple.is-style-button:focus {
  outline-width:0
}
.contact-form input.grunion-field.is-style-button+.grunion-field-text:before {
  background:var(--jetpack--contact-form--button-outline--background-color);
  border:var(--jetpack--contact-form--button-outline--border);
  border-color:currentColor;
  border-radius:var(--jetpack--contact-form--button-outline--border-radius);
  box-sizing:initial;
  content:"";
  display:block;
  height:100%;
  left:calc(var(--jetpack--contact-form--button-outline--border-size)*-1);
  position:absolute;
  top:calc(var(--jetpack--contact-form--button-outline--border-size)*-1);
  width:100%;
  z-index:-1
}
.contact-form input.grunion-field.is-style-button {
  color:var(--jetpack--contact-form--button-outline--color)
}
.contact-form input.grunion-field.is-style-button:checked,
.contact-form input.grunion-field.is-style-button:checked+.grunion-field-text {
  color:var(--jetpack--contact-form--button-outline--background-color-fallback)
}
.contact-form input.grunion-field.is-style-button:checked+.grunion-field-text:before {
  background:var(--jetpack--contact-form--button-outline--text-color);
  border-color:var(--jetpack--contact-form--button-outline--text-color)
}
.contact-form__error,
.contact-form__input-error {
  --warning-icon-size:1.25em;
  --warning-icon-margin:0.75em;
  align-items:center;
  display:flex;
  flex-wrap:wrap;
  font-size:1rem
}
.contact-form__error {
  background-color:var(--jetpack--contact-form--error-color);
  color:var(--jetpack--contact-form--inverted-text-color);
  gap:var(--warning-icon-margin);
  margin-bottom:var(--wp--style--block-gap,1.5rem);
  padding:1em
}
.contact-form__error ul {
  flex-basis:100%;
  list-style-position:inside;
  margin:0;
  padding-inline-start:calc(var(--warning-icon-size) + var(--warning-icon-margin))
}
.contact-form__error ul:empty {
  display:none
}
.contact-form__error ul li {
  padding:.25em 0
}
.contact-form__error a {
  color:inherit
}
.contact-form__input-error {
  color:var(--jetpack--contact-form--error-color);
  gap:.33em;
  margin:.25rem 0
}
.contact-form__error:empty,
.contact-form__input-error:empty {
  display:none
}
.contact-form .wp-block-jetpack-contact-form.is-style-outlined fieldset[aria-invalid=true],
.contact-form [aria-invalid=true]:not(fieldset) {
  border:1px solid var(--jetpack--contact-form--error-color)
}
.contact-form .wp-block-jetpack-contact-form:not(.is-style-outlined) fieldset[aria-invalid=true] {
  outline:solid 1px var(--jetpack--contact-form--error-color);
  outline-offset:.5em
}
.contact-form__warning-icon {
  background-color:var(--jetpack--contact-form--error-color);
  border:1px solid var(--jetpack--contact-form--inverted-text-color);
  border-radius:50%;
  color:var(--jetpack--contact-form--inverted-text-color);
  height:var(--warning-icon-size);
  width:var(--warning-icon-size)
}
.contact-form__warning-icon i:after {
  align-items:center;
  content:"!";
  display:flex;
  font-size:.8em;
  font-style:normal;
  font-weight:700;
  justify-content:center
}
.contact-form__checkbox-wrap {
  align-items:baseline;
  display:inline-flex
}
.contact-form :is([type=submit],
button:not([type=reset])) {
  align-items:center;
  display:inline-flex;
  gap:.5em;
  justify-content:center
}
.contact-form .contact-form__spinner {
  fill:currentColor
}
.contact-form .contact-form__spinner svg {
  display:block
}
.visually-hidden {
  clip:rect(0 0 0 0);
  -webkit-clip-path:inset(50%);
  clip-path:inset(50%);
  height:1px;
  overflow:hidden;
  position:absolute;
  white-space:nowrap;
  width:1px
}
